import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';

const AboutUs = () => {
  return (
      <Layout>
        <Seo title="About Us"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>About Us</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  YanchWare is a leading provider of products, services, and training to empower
                  businesses to take control of their technology.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  We are committed to providing businesses with
                  the necessary tools, services, and training to achieve their goals and succeed in today's
                  ever-changing business landscape independently.
                </Trans>
              </p>
              <Link to="/join-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Join us</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="about-us-page/about-us-hero" className="hero__image"/>
          </div>
        </section>

        <section className="container">
          <h2>
            <Trans>Unlock the Full Potential of Cloud Computing</Trans>
          </h2>
          <p>
            <Trans>
              With over a decade of industry experience, we've observed that many enterprises approach
              cloud computing without a robust strategy. This often leads to suboptimal results, as
              companies find themselves stuck between the chaos of ungoverned environments and overly
              restrictive cloud "dictatorships".
            </Trans>
          </p>
          <h2>
            <Trans>Introducing Our Secret Sauce: Fractal Architecture</Trans>
          </h2>
          <p>
            <Trans>
              At YanchWare, we believe we've developed a groundbreaking approach to cloud computing, which we call{' '}
              <strong>"Fractal Architecture"</strong>.
            </Trans>
          </p>
          <p>
            <Trans>
              Over the past two years, we've successfully implemented this strategy across various industries,
              collaborating with large enterprises to achieve remarkable results.
            </Trans>
          </p>
          <p>
            <Trans>
              Our Fractal Architecture enables rapid dissemination of best practices in cloud computing within and
              across organizations. This approach facilitates seamless, ongoing development and delivery on cloud
              infrastructure. With minimal need for specialized support, we reduce operational friction and enhance
              the synergy between development, security, and operations teams (DevSecOps).
            </Trans>
          </p>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>We are proud of our Achievements</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/legacy"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h3>
                  <Trans>Transforming Legacy Systems into Competitive Advantages</Trans>
                </h3>
                <p>
                  <Trans>
                    We've re-engineered Mainframe <strong>ERP systems into highly responsive event sources</strong>,
                    streaming thousands of messages per second. By doing so, we enable our customers'
                    microservices to access and process data in near real-time, bypassing the need for expensive,
                    heavy nightly jobs. This transformation not only improves operational efficiency but also
                    enhances decision-making with faster data availability.
                    Case studies from sectors like finance and retail illustrate how our solutions have cut costs
                    and increased responsiveness.
                  </Trans>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/revenue-streams"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h3>
                  <Trans>Creating New Revenue Streams</Trans>
                </h3>
                <p>
                  <Trans>
                    Working closely with industry leaders, we've converted traditional on-premises products into fully
                    scalable SaaS solutions. This transition not only broadens market reach but also introduces more
                    flexible pricing models and reduces maintenance overhead. Our clients see up to
                    <strong>70% of costs reduction</strong> within the first year of adopting our cloud-based products,
                    demonstrating the profitability and scalability of our approach to cloud-native transformations.
                  </Trans>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h3>
                  <Trans>Empowering Our Customers' Success</Trans>
                </h3>
                <p>
                  <Trans>
                    Our approach to Cloud Native transformations involves comprehensive support across infrastructure
                    automation, application security, and ongoing training programs. By automating infrastructure
                    deployment, we reduce time-to-market and operational costs. We enhance security protocols, ensuring
                    that applications are both robust and compliant with the latest regulations. Our training modules
                    are tailored to empower in-house teams, making them self-sufficient in maintaining and scaling their
                    solutions. Feedback from our clients highlights improved operational efficiency and reduced
                    dependency
                    on external support.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <h2 className="color-primary tac">
            <Trans>Our History: A Foundation Built on Passion and Innovation</Trans>
          </h2>
          <p>
            <Trans>
              At YanchWare, our journey is as unique as the innovations we bring to the field of cloud computing.
              Our founder, Angelo Agatino Nicolosi, left Sicily in 2007, drawn by a passion for Cryptography and a
              desire to study with the renowned crypto research group at Aarhus University in Denmark. This decision was
              pivotal, shaping not only his professional trajectory but also the core values of YanchWare.
            </Trans>
          </p>
          <p>
            <Trans>
              Angelo was captivated by the dynamic academic environment at Aarhus, marked by a blameless culture, flat
              hierarchy, and a close-knit community of teachers and students passionate about their work. But it was the
              discovery that these principles extended beyond the university into Danish society that truly inspired
              him.
              This integration of openness, continuous improvement, and community involvement in everyday business
              practices deeply influenced his vision for YanchWare.
            </Trans>
          </p>
        </section>
        <section className="our-vision accent-background">
          <div className="container content-blocks content-blocks--reverse content-blocks--no-image-bg">
            <div className="content-block">
              <Image
                  name="about-us-page/our-vision"
                  className="content-block__image"
              />
              <div className="content-block__info color-white">
                <h3 className="tac-p">
                  <Trans>Our Vision: Exporting Nordic Excellence Globally</Trans>
                </h3>
                <p>
                  <Trans>
                    Inspired by the Nordic model, YanchWare is committed to replicating these ideals globally.
                    Our goal transcends the typical business metrics; we aim to bridge cultural and economic divides
                    between the north and south, and the east and west of our world.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    Through our innovative cloud solutions, products, and training programs, we not only create jobs
                    but invest in people. We are dedicated to fostering professional growth and development across
                    geographical boundaries, ensuring that the benefits of our approach are accessible to everyone,
                    everywhere.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    By embodying these values, YanchWare doesn’t just export software solutions—we export a way of
                    doing business that is sustainable, inclusive, and fundamentally human.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container tac">
          <h2 className="color-primary">
            <Trans>Sustainable Development Goals</Trans>
          </h2>
          <div className="sustainable-development g-1-1-3 ">
            <div className="sustainable-development-item">
              <Image name="about-us-page/quality-education"/>
              <h3>
                <Trans>4. Quality Education</Trans>
              </h3>
              <p>
                <Trans>
                  Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.
                </Trans>
              </p>
            </div>
            <div className="sustainable-development-item">
              <Image name="about-us-page/economical-growth"/>
              <h3>
                <Trans>8. Decent Work and Economic Growth</Trans>
              </h3>
              <p>
                <Trans>
                  Promote sustained, inclusive and sustainable economic growth, full and productive employment and
                  decent
                  work for all
                </Trans>
              </p>
            </div>
            <div className="sustainable-development-item">
              <Image name="about-us-page/sustainable-communities"/>
              <h3>
                <Trans>10. Reduced Inequalities</Trans>
              </h3>
              <p>
                <Trans>Reduce inequality within and among countries.</Trans>
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <p>&nbsp;</p>
        </section>
      </Layout>
);
};

export default AboutUs;
